.nav-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    height: 70px;
    justify-content: space-around;
    background-color: azure;
}

.logo {
    width: 8%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}

.no-carr {
    width: 20%;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-nav {
    width: 80%;
}

.search {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-nav {
    color: black !important;
    font-weight: bold !important;
    font-size: 1.1rem !important;
}

.container-nav2 {
    display: flex;
    width: 60%;
    justify-content: flex-end;
    gap: 20px;
}

.container-nav1 {
    display: flex;
    align-items: center;
    width: 80%;
    justify-content: space-between;
}

.title-nav{
    font-size: 50px;
    font-family: emoji!important;
    cursor: pointer;
}